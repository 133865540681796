section.room-section.hotel_section.box-section-gray > div > div.box-content {
  flex: 2;
}

section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-content
  > div
  > div
  > div
  > div {
  width: 100% !important;
}

section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-content
  > div
  > div
  > div
  > div
  > div.carousel.carousel-slider
  > div
  > ul
  > li
  > div
  > img {
  object-fit: cover;
  height: 400px;
}

section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-content
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > ul {
  height: 100px;
}

section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-content
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > ul
  > li {
  flex: 1;
}

section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-content
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > ul
  > li
  > img {
  height: 50px;
  object-fit: cover;
}

section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-right.list-item-room
  > div {
  margin-bottom: 20px;
}

.item-room {
  box-shadow: 2px 1px 10px rgba(3, 18, 26, 0.2);
  color: #333;
  padding: 16px;
}
.item-room hr {
  border-color: #878787;
  margin: 10px 0 14px;
}
.item-room .top-room {
  display: flex;
  justify-content: space-between;
}
.item-room .top-room .box-left {
  width: 60%;
  font-size: 14px;
}
.item-room .top-room .box-left .title-room {
  display: block;
  color: #333;
  margin-bottom: 0;
}
.item-room .top-room .box-left .info-room {
  display: flex;
  margin-top: 0.7rem;
}
.item-room .top-room .box-left .info-room .item {
  margin-right: 16px;
}
.item-room .top-room .box-left .info-room .item .icon {
  display: inline-block;
  width: 16px;
  margin-right: 2px;
}
.item-room .top-room .box-left .info-room .item i {
  margin-left: 3px;
  margin-right: 2px;
  color: #28a745;
  font-size: 16px;
}
.item-room .top-room .box-left .info-room .item i.fa-baby {
  font-size: 14px;
}
.item-room .top-room .number-vacancy {
  color: #ff5e1f;
  font-size: 0.8rem;
  margin-top: 4px;
}
.item-room .content-room {
  display: flex;
  column-gap: 14px;
  font-size: 0.85rem;
}

section.room-section.hotel_section.box-section-gray
  > div
  > div.box-right.list-item-room
  > div
  > div.top-room {
  display: flex;
}

section.room-section.hotel_section.box-section-gray
  > div
  > div.box-right.list-item-room
  > div
  > div.top-room
  > div.price-room
  > div.price {
  color: var(--orange-text-color);
  font-size: 16px;
  font-weight: 600;
}

section.room-section.hotel_section.box-section-gray
  > div
  > div.box-right.list-item-room
  > div
  > div.content-room {
  justify-content: space-between;
}

section.room-section.hotel_section.box-section-gray
  > div
  > div.box-right.list-item-room
  > div
  > div.content-room
  > div.choose-room
  > div
  > div.input-group
  > select {
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--bs-border-color);
  border-radius: 5px;
}

/* Contains */
section.room-section.hotel_section.box-section-gray
  > div
  > div.box-right.list-item-room
  > div
  > div.content-room
  > div.list-endow {
  color: var(--screen-text-color);
  font-weight: 500;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0;
}

#content-layout__body
  > div
  > div
  > section.room-section.hotel_section.box-section-gray.room_detail
  > div
  > div.box-content
  > div
  > div
  > div
  > div {
  height: 100% !important;
  width: 100% !important;
}
