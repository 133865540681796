.payment__body__policies {
  margin-bottom: 20px;
}

.payment__body__policies .notif_container {
  background-color: #d1ecf1;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.payment__body__policies .notif_container span {
  display: block;
}

.payment__body__policies .notif_container .notif {
  font-weight: 500;
  margin-bottom: 20px;
}

.payment__body__policies .notif_container .notif_more {
  color: #ff6000;
}

.confirmation_container {
}
