.modal-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 100;
}

.bg_container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
}

.modal-container .modal-content {
  width: 30%;
  z-index: 1000;
}

div.modal-content > div.modal-body > h4 > span {
  font-size: 25px;
}

.modal-body {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

#frmLogin > div.row.item > div {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

#frmLogin > div.row.item > div span {
  position: absolute;
  bottom: -30px;
  color: red;
}

#frmLogin > div.row.item > div > label {
  width: 20%;
  display: inline-block;
}

#frmLogin > div.row.item > div > input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  width: 100%;
  border-radius: 8px;
}

#frmLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#frmNewCustomer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#frmNewCustomer > div.row.item > div {
  margin-bottom: 20px;
}

#frmLogin > div.row.item > div {
  display: flex;
}

#frmNewCustomer > div.row.item > div > label {
  display: block;
  width: 200px;
}

#frmNewCustomer > div.row.item > div > input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px;
  width: 100%;
  border-radius: 8px;
}

div.modal-body > h4 > span {
  font-weight: 700;
  font-size: 20px;
}

.remember_login {
  display: flex;
  justify-content: space-between;
}

.already-account {
  margin-top: 20px;
}

.already-account a {
  color: var(--bs-link-color);
}

#frmNewCustomer > div.row.item > div {
  display: flex;
}

#frmNewCustomer > button {
  background-color: var(--bs-link-color);
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
}

#frmLogin > .btn-register-container {
  background-color: var(--bs-link-color);
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
}

#root > div > div.modal-container > div.modal-content > div > small {
  font-size: 16px;
}

#frmNewCustomer > div.row.item > div {
  display: flex;
  flex-direction: column;
}
