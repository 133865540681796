.profile-page__content {
  padding: 25px 0;
  width: 80%;
}

.profile-page__content .profile-page__content-tab {
  display: flex;
}

.profile-page__content .profile-page__content_tablist {
  width: 300px;
}

.profile-page__content .profile-page__content-tab .tab-panel-item {
  font-size: 20px;
  font-weight: 500;
}

.profile-page__content .profile-page__content_tablist {
  margin-right: 40px;
}

.profile-page__content .profile-page__content_tablist .tab-item {
  font-size: 16px;
  text-transform: uppercase;
  padding: 8px 16px;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  outline: none;
  background-color: white;
}

.profile-page__content .profile-page__content_tablist .tab-item:last-child {
  border-bottom: 1px solid #e5e5e5;
}

.profile-page__content .profile-page__content_tablist .tab-item_active {
  background-color: red;
}

.profile-page__content .profile-page__content_tablist .tab-panel-item {
  padding: 20px 0;
}

.react-tabs__tab--selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
