.tour_detail__body__policies {
  display: flex;
  flex-direction: column;
}

.tour_detail__body__policies .policies_body {
  background-color: var(--bs-white);
}

.tour_detail__body__policies .policies_body .row_policies {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tour_detail__body__policies .policies_body .row_policies:first-child .row_policies_left-box {
  width: 200px;
  border-right: 1px solid var(--bs-border-secondary-color);
}

.tour_detail__body__policies .policies_body .row_policies:not(:first-child) .row_policies_left-box {
  width: 200px;
  border-right: 1px solid var(--bs-border-secondary-color);
}

.tour_detail__body__policies .policies_body .row_policies:not(:first-child) {
  border-top: 1px solid var(--bs-border-secondary-color);
  border-right: 1px solid var(--bs-border-secondary-color);
}

.tour_detail__body__policies .policies_body .row_policies div {
  padding: 16px;
}
