#total-tour_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#total-tour_container h2 {
  margin: 20px 0;
  font-size: 20px;
  font-weight: 500;
}

#total-tour_container .box-container .box-item {
  display: flex;
  width: 250px;
  justify-content: space-between;
}

#total-tour_container .box-container .box-item .left-box {
  font-weight: 700;
}

#total-tour_container .box-container .box-item .right-box {
  color: #ff6000 !important ;
}

#total-tour_container .box-container .slight .left-box {
  font-weight: 400;
}

#total-tour_container .box-container .box-item .right-box {
  font-weight: 700;
}

#total-tour_container .box-container .slight .right-box {
  font-weight: 400;
}
