#invoice_detail_container {
  display: flex;
  flex-direction: column;
}

#invoice_detail_container h2 {
  font-weight: 500;
  margin: 20px 0;
  font-size: 20px;
}

#invoice_detail_container .title_product {
  width: 100%;
  border-bottom: 1px solid #eb7a2a;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

#invoice_detail_container .title_product span {
  font-weight: 600;
}

#invoice_detail_container .table-container {
  display: flex;
  flex-direction: column;
}

#invoice_detail_container .table-container .heading_container {
  display: flex;
  background-color: #eb7a2a;
  color: white;
  font-weight: 700;
  padding: 10px 5px;
  margin-bottom: 10px;
}

#invoice_detail_container .table-container .data_container {
  display: flex;
  flex-direction: column;
}

#invoice_detail_container .table-container .data_container .row_container {
  display: flex;
}

#invoice_detail_container .table-container .data_container .row_container .row-item {
  width: 150px;
  padding: 0 10px;
}

#invoice_detail_container .table-container .heading_container .col-item {
  width: 150px;
}
