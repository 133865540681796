#policy-section {
  border-radius: 8px;
}

#policy-section > div > div.item-policy {
  display: flex;
  border: 1px solid var(--bs-border-color);
}

#policy-section > div > div.item-policy:first-child {
  border-bottom: 0;
}

#policy-section > div > div.item-policy .title {
  width: 25%;
  flex-direction: column;
  display: flex;
  padding: 10px 16px;
  font-weight: 500;
}

#policy-section > div > div > div.desc {
  padding: 16px 16px;
  border-left: 1px solid var(--bs-border-color);
}

#policy-section > div > div > div.desc {
  flex: 1;
}
