.attraction {
  background-color: var(--main-bg-color);
}

#attraction_wrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

#attraction_content {
  display: flex;
  justify-content: space-between;
}

.attraction_container {
  margin-bottom: 20px;
}
