.hotel_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hotel_container .menu-section {
  margin-bottom: 20px;
}

.hotel_container .menu-section > a {
  color: var(--secondary-text-color);
  font-weight: 700;
  margin-right: 10px;
}

.hotel_container div.carousel-root > div > div > ul {
  display: flex;
  justify-content: space-between;
}

.hotel_section {
  width: 80%;
  background-color: var(--bs-white);
  padding: 16px;
  border-radius: 8px;
}

.hotel_container .img-container {
  width: 100%;
}

div.menu-section {
  width: 100%;
}

#quick-convenient-section > div.list-convenient {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#quick-convenient-section > div.list-convenient > div {
  text-align: center;
}

#quick-convenient-section {
  width: 80%;
}

#root > div.py-6 > div > div > div > div.hotel_section__carousel_container > div {
  width: 50%;
  height: 50%;
}

#quick-convenient-section > div.list-convenient > div .box-img-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

#quick-convenient-section > div.list-convenient > div img {
  width: 50%;
}

div.hotel_section__carousel_container > div > div:nth-child(2) > div > ul {
  padding: 0;
  margin: 0;
  height: 60px;
}

.carousel .thumbs-wrapper {
  margin: 0;
  margin-top: 10px;
}

.carousel .thumb {
  margin-right: 0;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 0;
}

div.hotel_section__carousel_container > div > div:nth-child(2) > div > ul > li {
  border: 0px;
  flex: 1;
}

#quick-convenient-section > div.text-center > a {
  color: var(--secondary-text-color);
  font-weight: 700;
}

.hotel_section__carousel_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

div.hotel_section__carousel_container > div > div.carousel.carousel-slider {
  height: 500px;
}

div.carousel-root {
  width: 60%;
}

#quick-convenient-section > div.list-convenient {
  margin: 10px 0px;
}

#quick-convenient-section > div.list-convenient > div > div.box-img-flex > img {
  width: 40px;
}

div.hotel_section__carousel_container > div > div.carousel.carousel-slider > div > ul > li > div {
  height: 100%;
}
