/* heading */
#payment__body__booking {
  width: 350px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
}

#payment__body__booking .heading {
  display: flex;
  border: 1px solid var(--bs-border-secondary-color);
}

#payment__body__booking .heading .left-box {
  width: 150px;
  margin-right: 20px;
}

#payment__body__booking .heading .left-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#payment__body__booking .heading .right-box {
  width: 210px;
}

#payment__body__booking .heading .right-box span {
  display: block;
}

#payment__body__booking .heading .right-box .title {
  overflow: hidden;
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
}

#payment__body__booking .heading .right-box .tour_name {
  color: #a1a1a1;
}

/* body */

#payment__body__booking .body .body_item {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
}

#payment__body__booking .body .body_item:nth-child(4) {
  flex-direction: row;
}

#payment__body__booking .body .body_item .title {
  font-weight: 500;
  color: #154eb5;
  text-transform: uppercase;
}

#payment__body__booking .body .body_item .left-box {
  flex: 1;
}

#payment__body__booking .body .body_item .center-box {
  width: 100px;
}

#payment__body__booking .body .body_item .right-box {
  flex: 1;
  text-align: end;
  color: #ff6000;
  font-weight: 600;
}

#payment__body__booking .body .body_item:nth-child(2) {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

#payment__body__booking .body .type_person {
  border-radius: 3px;
  border: 1px solid #004085;
  flex-direction: row;
}

#payment__body__booking .body .bottom_item {
  display: flex;
  justify-content: space-between;
}

#payment__body__booking .body .bottom_item .right-box {
  color: #ff6000;
}

#payment__body__booking .body .amount .right-box {
  font-weight: 700;
  font-size: 24px;
}

#payment__body__booking .body .advance_fees {
  color: #ff6000;
}

#payment__body__booking .body .advance_fees .right-box {
  font-weight: 500;
}
