.attraction_container {
  display: flex;
  width: 100%;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.attraction_heading {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.attraction_heading > div.box_right > div.attraction_title > h2 {
  font-size: 26px !important;
  font-weight: 500;
  margin-bottom: 10px;
}
.attraction_info_provider.box-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.attraction_info_provider.box-left .box-item-top {
  margin-bottom: 10px;
}
.attraction_info_provider.box-left span {
  font-size: 26px;
  font-weight: 500;
  color: var(--bs-orange) !important;
}

.attraction_heading > div.attraction_info_provider.box-left > a {
  text-transform: uppercase;
  color: #005fa2;
  border: #005fa2 1px solid;
  padding: 6px 20px;
}
