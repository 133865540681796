.tour_detail__body__full-combo {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--bs-white);
  border-radius: 8px;
}

.tour_detail__body__full-combo .full-combo__box-heading {
  width: 50%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  border-top: 1px solid var(--bs-blue-ocean);
  border-left: 1px solid var(--bs-blue-ocean);
  border-top-left-radius: 8px;
}
.tour_detail__body__full-combo .full-combo__box-body {
  display: flex;
  width: 100%;
}
.tour_detail__body__full-combo .full-combo__box-body__left-item {
  flex: 1;
  padding: 16px;
}

.tour_detail__body__full-combo .full-combo__box-body__left-item .title {
  font-size: 20px !important;
  font-weight: 600;
  text-transform: capitalize;
}

.tour_detail__body__full-combo .full-combo__box-body__right-item {
  padding: 16px;
  flex: 1;
}

.tour_detail__body__full-combo .full-combo__box-body__right-item .title {
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
}
