.tour-page__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--main-bg-color);
}

.tour_container {
  margin: 20px 0;
}

#tour-page__container {
  width: 80%;
}

.tour-page__container-body {
  display: flex;
}

.tour-page__container-content {
  flex: 1;
  margin-right: 20px;
}
