#payment__body__customer-infomation {
  margin-bottom: 50px;
}

#payment__body__customer-infomation .input_field .input_item {
  border: 1px solid black;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  margin-bottom: 10px;
}

#payment__body__customer-infomation .input_field .input_item label {
  position: absolute;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  top: -14px;
  background-color: white;
}

#payment__body__customer-infomation .heading h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

#payment__body__customer-infomation .input_field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

#payment__body__customer-infomation .input_field .input_container {
  display: flex;
  margin-bottom: 20px;
}

#payment__body__customer-infomation .input_field .input_item {
  width: 30%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  margin-right: 20px;
}
