#convenient-section > div.list-convenient {
  display: flex;
}

#convenient-section > div.list-convenient > div {
  flex: 1;
}

#convenient-section > div.list-convenient > div > div {
  display: flex;
  align-items: center;
}

#convenient-section > div.list-convenient > div > div > img {
  width: 5%;
  margin-right: 10px;
}

#convenient-section > div.list-convenient > div > ul {
  padding: 10px 26px;
}

#convenient-section > div.list-convenient > div li {
  list-style-type: circle;
}
