.invoice-page__wrapper {
  width: 100%;
  background-color: #f4f6f9;
  padding: 50px 0;
  display: flex;
  justify-content: center;
}

.invoice-page__wrapper > div {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.invoice-page__container {
  width: 80%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
}

/* sidebar */

.invoice-page__sidebar_container {
  margin-left: 30px;
  width: 30%;
}

.invoice-page__sidebar {
  padding: 16px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.invoice-page__sidebar .location {
  color: #a1a1a1 !important;
  font-weight: 500;
  margin-top: 20px;
}

.invoice-page__sidebar a {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.invoice-page__sidebar a h6 {
  font-weight: 600;
  font-size: 16px;
  margin-left: 20px;
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

.invoice-page__sidebar a img {
  width: 100px;
}

.invoice-page__sidebar .separate {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 1px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.invoice-page__sidebar .body {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 10px;
  min-height: 400px;
}

.invoice-page__sidebar .body h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 20px;
}

.invoice-page__sidebar .body p {
  font-weight: 500;
}

.sidebar-contact__wrapper {
  padding: 16px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
}

.sidebar-contact__wrapper h4 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}
