.offer-page__wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: var(--main-bg-color);
}

#offer-page__container {
  display: flex;
  width: 90%;
  justify-content: center;
}

.offer-sidebar-container {
  margin-left: 20px;
}

.offer-sidebar-wrapper {
  background-color: var(--bs-white);
}

.offer-sidebar-wrapper .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.offer-sidebar-wrapper .heading .price {
  color: var(--bs-orange);
  font-weight: 700;
}
