.tour_detail__body__booking {
  background-color: var(--bs-white);
}

.tour_detail__body__booking .booking_container__heading {
  background-color: var(--bs-blue-ocean);
}

.tour_detail__body__booking .booking_container__heading h2 {
  font-weight: 500;
  font-size: 20px;
  color: white;
  padding: 8px 16px;
}

/* body */
.tour_detail__body__booking .booking_container__body {
  padding: 8px 16px;
  background-color: var(--bs-white);
}

.tour_detail__body__booking .booking_container__body .booking_by_date form {
  display: flex;
  margin-bottom: 20px;
}

.tour_detail__body__booking .booking_container__body .booking_by_date input[type='date'] {
  border: 1px solid var(--bs-border-secondary-color);
  margin-right: 10px;
  font-size: 14px;
  display: inline-block;
}

.tour_detail__body__booking .booking_container__body .booking_by_date input[type='submit'] {
  background-color: var(--bs-orange);
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 6px;
  color: var(--bs-white);
  font-size: 14px;
}

.tour_detail__body__booking .booking_container__body .booking_by_date {
  border-bottom: 1px solid var(--bs-border-secondary-color);
}

#tour_detail__body__booking {
  border-top: 1px solid var(--bs-border-secondary-color);
  margin-top: 2px;
}

#booking_container__submit_form .booking_form_item {
  padding: 16px 0;
  display: flex;
}

#booking_container__submit_form .booking_form_item .price {
  color: var(--bs-orange);
  margin-left: 20px;
}

#booking_container__submit_form input[type='text'] {
  border: 1px solid var(--bs-border-secondary-color);
  width: 50px;
  margin-right: 20px;
}

#booking_container__submit_form .booking_order {
  border-top: 2px solid var(--bs-border-secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#booking_container__submit_form .booking_order .total h3 {
  font-size: 20px;
}

#booking_container__submit_form .booking_order .price {
  font-size: 20px;
  font-weight: 600;
  color: var(--bs-orange);
}

#booking_container__submit_form .booking_order .fees_advance {
  font-size: 16px;
}

#booking_container__submit_form .booking_order {
  border-bottom: 1px solid var(--bs-border-secondary-color);
  margin-bottom: 2px;
}

#booking_container__submit_form .order_submit {
  border-top: 1px solid var(--bs-border-secondary-color);
  margin-top: 2px;
}

#booking_container__submit_form .order_submit input[type='submit'] {
  text-transform: uppercase;
  color: var(--bs-white);
  background-color: var(--bs-orange);
  width: 100%;
  padding: 10px 0;
}
