#payment__body__personal-infomation {
  background-color: var(--bs-white);
}

.payment-page__wrapper {
  width: 100%;
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.payment-page__wrapper .form-container {
  width: 70%;
}

.payment-page__container {
  display: flex;
  justify-content: space-between;
}

#content-layout__body {
  flex: 1;
  height: 100%;
}

.tab-panel__container {
  display: flex;
  flex-wrap: wrap;
}

.tab-panel__container input.guest_info {
  border: 1px solid var(--bs-border-secondary-color);
}

.tab-panel__container .guest_info {
  flex: 40%;
  margin: 5px 16px;
}

.payment-page__booking {
  margin-left: 20px;
}

#content-layout__body > .form-container .payment-page__submit-btn {
  width: 100%;
  text-align: center;
}

#content-layout__body > .form-container .payment-page__submit-btn button {
  text-transform: uppercase;
  color: white;
  background-color: #0d9d66;
  width: 20%;
  padding: 10px 0;
  border-radius: 3px;
}

#payment__body__personal-infomation .heading h2 {
  font-size: 24px;
  margin: 20px 0px;
}

#payment__body__personal-infomation .payment_tablist .payment_tabitem {
  width: 300px;
  display: inline-block;
  text-align: center;
  padding: 8px 0;
  margin-bottom: 20px;
  outline: none;
}

.payment_tab-item input {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 4px;
  width: 100%;
}

.tab-panel__container .guest_info {
  position: relative;
  display: flex;
}

.tab-panel__container .guest_info:nth-child(odd) {
  margin-left: 0;
  margin-right: 20px;
}
