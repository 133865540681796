.tour_detail__heading {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.tour_detail__heading_item {
  display: flex;
  justify-content: space-between;
}

.tour_detail__heading .tour_detail__heading_top .left-box h2 {
  font-size: 24px;
}

.tour_detail__heading .tour_detail__heading_top .right-box .price {
  color: var(--bs-orange);
  margin-left: 10px;
  font-size: 24px;
  font-weight: 500;
}

.tour_detail__heading .tour_detail__heading_bottom .detail {
  color: var(--bs-blue-ocean);
  border: 1px solid var(--bs-blue-ocean);
  padding: 5px 20px;
}
