.box-size01 {
  overflow: hidden;
}

.box-size01 img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.box-right {
  font-size: 0.8rem;
}

.box-left {
  font-size: 0.9rem;
}

.box-left span {
  color: #ff6000;
  font-weight: 600;
}

.box-size-bottom-container {
  background-color: rgb(223, 224, 225, 0.8);
}

#content-layout__body
  > div:nth-child(2)
  > div
  > div
  > div
  > div
  > div
  > div
  > div {
  padding-right: 20px;
  padding-left: 20px;
}

.img-container {
  width: 100%;
  height: 100%;
}

.img-container img {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.local_tours_item {
  width: calc(100% / 5);
}

.local_tours_item img {
  object-fit: cover;
}

.local_tours_item div:first-child {
  height: 200px;
}

.react-slideshow-wrapper {
  height: 250px;
}

.attraction_item {
  border-color: var(--bs-border-secondary-color);
  transition: border-color 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.attraction_item:hover {
  border-color: var(--bs-link-hover-color);
}

.ellipsis {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

div.grid.grid-cols-1.relative.click.last-item {
  overflow: hidden;
}
