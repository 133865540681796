#attraction_slideshow {
  flex: 1;
}

#attraction_slideshow > div.carousel-root {
  width: 100%;
}

.attraction_content_container .attraction_content_navigation {
  display: flex;
  flex-direction: row;
}

#attraction_slideshow .attraction_content_navigation li {
  margin-right: 20px;
  display: inline-block;
}

.attraction_body {
  margin-right: 20px;
}

.attraction_container {
  display: flex;
  flex-direction: column;
}

.attraction_container .attraction_carousel_item img {
  width: 80%;
  height: 100%;
  object-fit: cover;
}

.attraction_container > div > div:nth-child(2) > div > ul {
  overflow: hidden;
}

#attraction_slideshow > div > div:nth-child(2) {
  width: 80%;
  text-align: center;
}

.attraction_carousel.thumb_item {
  height: 60px;
  position: relative;
}

.attraction_carousel__background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
}

.attraction_carousel.thumb_item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
