#confirmation-tour_container {
  background-color: white;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

#confirmation-tour_container h2 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
}

#confirmation-tour_container .box-container {
  display: flex;
  flex-wrap: wrap;
}

#confirmation-tour_container .box-container .box-item {
  display: flex;
  flex: 50%;
  padding: 10px 0;
}

#confirmation-tour_container .box-container .box-item .left-box span {
  font-weight: 500;
  margin-right: 5px;
}
