.attraction_content_container {
  flex: 1;
}

#detail-content-sticky-nav-01 > div.fullwidth-horizon-sticky-section.pt-0 {
  margin-top: 10px;
}

#detail-content-sticky-nav-01 > div.fullwidth-horizon-sticky-section.pt-0 > h3 > span {
  font-weight: 500;
  font-size: 24px;
}

#detail-content-sticky-nav-05 > h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 24px;
}

#detail-content-sticky-nav-05 > div {
  display: inline-block;
}

#detail-content-sticky-nav-05 > div > div.box-item {
  display: flex;
}

#detail-content-sticky-nav-05 > div > div.box-item h6 {
  width: 200px;
  border-right: 1px solid var(--bs-border-secondary-color);
  padding: 16px;
}

#detail-content-sticky-nav-05 > div > div.box-item p {
  padding: 16px;
}

#detail-content-sticky-nav-05 > div > div.box-item:nth-child(2) h6 {
  border-top: 1px solid var(--bs-border-secondary-color);
}

#detail-content-sticky-nav-05 > div > div.box-item:nth-child(2) p {
  border-top: 1px solid var(--bs-border-secondary-color);
}
