.heading_page_container {
  padding: 10px 0;
}

.heading_page_container > div > div > ul li {
  margin-left: 10px !important;
}

.heading_page_container > div > .right-box .top-item {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.heading_page_container > div > .right-box .top-item li {
  width: 100px;
  border: 1px solid #3795fa;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  color: #3795fa;
}

.heading_page_container > div > .right-box .top-item li:last-child {
  background-color: #3795fa;
  color: white;
}

#root > div > div > div.flex.justify-center.heading_page_container > div > img {
  width: 100px;
}
