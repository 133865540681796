.prod-lst {
  width: 100%;
  background-color: #e6eaed;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box-section-white {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin: 10px 100px;
  margin-top: 10px;
  width: 60%;
}

div.box-img.mr-4 > img {
  width: 300px;
}

div.box-content > div.box-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
}

.box-section-white .list-hotel .box-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.box-section-white .list-hotel .box-content .box-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

div.list-hotel > div > div.box-content > div.box-right > div {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

div.list-hotel > div > div.box-content > div.box-right > div .day {
  text-align: right;
}

div.list-hotel > div > div.box-content > div.box-right > div .people.day {
  color: #595959;
}

div.list-hotel > div > div.box-content > div.box-right > div > div.price-room > div.price {
  color: #ff5e1f;
  font-weight: 700;
  font-size: 20px;
  text-align: right;
}

.list-icon {
  display: flex;
}

.list-icon .item img {
  width: 20px;
}

.type-hotel {
  background-color: #2e90fa;
  display: inline-block;
  color: white;
  border-radius: 8px;
  padding: 0px 10px;
  font-weight: 700;
}

div.list-hotel > div > div.box-content > div.box-right > div > p {
  background-color: #ff5e1f;
  color: white;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 10px;
}

section.room-section.hotel_section.box-section-gray
  > div.box-container
  > div.box-content
  > div
  > div
  > div
  > div {
  height: 350px !important;
  width: 350px !important;
}

section.room-section.hotel_section.box-section-gray {
  display: flex;
  flex-direction: column;
}

section.room-section.hotel_section.box-section-gray .box-container {
  display: flex;
}

section.room-section.hotel_section.box-section-gray .box-container .box-right.list-item-room {
  flex: 1;
  margin-left: 30px;
}

div.list-hotel div.flex div.box-content div.box-right .box-price.item-room {
  box-shadow: none;
}
