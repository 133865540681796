#info_customer-tour_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#info_customer-tour_container h2 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
}

#info_customer-tour_container .box-container .box-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
