.profile-page__wrapper .heading_container {
  display: flex;
  align-items: center;
  width: 80%;
}

.profile-page__wrapper .heading_container .left-box {
  display: flex;
  align-items: center;
  width: 550px;
}

.profile-page__wrapper .heading_container .left-box h3 {
  font-weight: 700;
  font-size: 24px;
}

.profile-page__wrapper .heading_container .right-box span {
  font-weight: 700;
  font-size: 24px;
}
